// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-miller-squat-program-js": () => import("./../../../src/pages/miller-squat-program.js" /* webpackChunkName: "component---src-pages-miller-squat-program-js" */),
  "component---src-pages-remote-coaching-js": () => import("./../../../src/pages/remote-coaching.js" /* webpackChunkName: "component---src-pages-remote-coaching-js" */),
  "component---src-pages-thank-you-register-js": () => import("./../../../src/pages/thank-you-register.js" /* webpackChunkName: "component---src-pages-thank-you-register-js" */),
  "component---src-pages-thank-you-squat-js": () => import("./../../../src/pages/thank-you-squat.js" /* webpackChunkName: "component---src-pages-thank-you-squat-js" */),
  "component---src-templates-seminar-js": () => import("./../../../src/templates/seminar.js" /* webpackChunkName: "component---src-templates-seminar-js" */)
}

